<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="itemTable"
      title="사업장별 대기 검사항목"
      tableId="itemTable"
      :columns="griditem.columns"
      :data="griditem.data"
      :columnSetting="false"
      selection="multiple"
      rowKey="envAirPlantInspectItemId"
      :filtering="false"
      :usePaging="false"
      :editable="editable"
    >
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="추가" v-if="editable" icon="add" @btnClicked="addrowitem" />
          <c-btn label="삭제" v-if="editable" icon="remove" @btnClicked="removeRowitem" />
          <c-btn label="저장" v-if="editable" icon="save" @btnClicked="saveitem" />
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'air-master-08',
  props: {
    param: {
      type: Object,
      default: () => ({
      }),
    },
  },
  data() {
    return {
      searchParam: {
        plantCd: null,
        useFlag: 'Y',
      },
      editable: true,
      griditem: {
        columns: [
          {
            required: true,
            name: 'plantCd',
            field: 'plantCd',
            label: '사업장',
            align: 'center',
            sortable: true,
            style: 'width:200px',
            type: 'plant',
          },
          {
            name: 'airInspectTypeName',
            field: 'airInspectTypeName',
            label: '검사구분',
            align: 'center',
            sortable: true,
          },
          {
            name: 'envAirMstInspectItemName',
            field: 'envAirMstInspectItemName',
            label: '검사항목',
            align: 'center',
            sortable: true,
          },
          {
            name: 'airInspectUnitName',
            field: 'airInspectUnitName',
            label: '단위',
            align: 'center',
            style: 'width: 150px',
            sortable: true,
          },
          {
            name: 'legalStandard',
            field: 'legalStandard',
            label: '법적기준',
            align: 'center',
            type: 'cost',
            style: 'width: 120px',
            sortable: true,
          },
          {
            name: 'innerStandard',
            field: 'innerStandard',
            label: '내부기준',
            align: 'center',
            type: 'number',
            style: 'width: 120px',
            sortable: true,
          },
        ],
        data: [],
      },
      itemListUrl: '',
      itemCheckUrl: '',
      itemDeleteUrl: '',
      itemSaveUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '50%',
        param: {},
        closeCallback: null,
      },
    };
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.itemListUrl = selectConfig.env.air.mst.inspect.plant.url;
      this.itemCheckUrl = selectConfig.env.air.mst.inspect.check.url;
      this.itemDeleteUrl = transactionConfig.env.air.mst.inspect.plant.delete.url;
      this.itemSaveUrl = transactionConfig.env.air.mst.inspect.plant.save.url;
      
      this.getList();
    },
    getList() {
      this.$http.url = this.itemListUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.griditem.data = _result.data;
      },);
    },
    addrowitem() {
      this.popupOptions.title = '대기 검사항목 검색';
      this.popupOptions.param = {
        type: 'multiple',
      };
      this.popupOptions.target = () => import(`${'./inspectItemPop.vue'}`);
      this.popupOptions.width = '50%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeitem;
    },
    closeitem(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          /**
           * 2023-04-19 kdh
           * 
           * 로그인자 사업장 : 사업장1
           * 넣고자하는 사업장 : 사업장 2
           * 위의 경우 사업장1에 검사항목 F가 있는 경우 사업장 2에 추가할 수가 없음
           * 
           * 따라서 검사항목이 없을때만 넣는 로직 제거
           */
          // if (this.$_.findIndex(this.griditem.data, { envAirMstInspectItemId: item.envAirMstInspectItemId }) === -1) {
          this.griditem.data.splice(0, 0, {
            envAirPlantInspectItemId: uid(),  // 사업장별 대기 검사항목 일련번호
            plantCd: this.searchParam.plantCd,  // 사업장코드
            envAirMstInspectItemId: item.envAirMstInspectItemId,  // 대기 검사항목 일련번호
            airInspectTypeName: item.airInspectTypeName,
            envAirMstInspectItemName: item.envAirMstInspectItemName,
            airInspectUnitName: item.airInspectUnitName,
            legalStandard: item.legalStandard,
            innerStandard: null,  // 내부기준
            editFlag: 'C',
            regUserId: this.$store.getters.user.userId
          })
          // }
        })
      }
    },
    saveitem() {
      if (this.$comm.validTable(this.griditem.columns, this.griditem.data)) {
        let saveData = this.griditem.data.filter( x => {
          return x.editFlag == 'C' || x.editFlag == 'U'
        });
        if(saveData.length > 0) {
          this.$http.url = this.itemListUrl;
          this.$http.type = 'GET';
          this.$http.request((_result) => {
            let duplicateFlag = false;
            this.$_.forEach(saveData, item => {
              // 전체
              let allCheck = this.$_.filter(_result.data, { 
                plantCd: item.plantCd,  
                envAirMstInspectItemId: item.envAirMstInspectItemId,  
              })
              // 현재 테이블
              let currentCheck = this.$_.filter(this.griditem.data, { 
                plantCd: item.plantCd,  
                envAirMstInspectItemId: item.envAirMstInspectItemId,  
              })

              // 수정인 경우 전체에서 자기자신 제외
              if (item.editFlag === 'U') {
                allCheck = this.$_.reject(allCheck, { envAirPlantInspectItemId: item.envAirPlantInspectItemId })
              }
              if (allCheck.length !== 0 || currentCheck.length !== 1) {
                duplicateFlag = true;
                return false;
              }

              item.regUserId = this.$store.getters.user.userId;
              item.chgUserId = this.$store.getters.user.userId;
            });

            if (!duplicateFlag) {
              window.getApp.$emit('CONFIRM', {
                title: '확인',
                message: '저장하시겠습니까?',
                type: 'info',
                // 확인 callback 함수
                confirmCallback: () => {
                  this.$http.url = this.itemSaveUrl;
                  this.$http.type = 'POST';
                  this.$http.param = saveData;
                  this.$http.request(() => {
                    this.getList();
                    window.getApp.$emit('APP_REQUEST_SUCCESS');
                  });
                },
                // 취소 callback 함수
                cancelCallback: () => {
                },
              });
            } else {
              window.getApp.$emit('ALERT', {
                title: '안내', 
                message: '입력하신 데이터 중 사업장, 검사항목이\n동일한 데이터가 존재합니다.', /* 입력하신 데이터 중 사업장, 검사항목이 동일한 데이터가 존재합니다. */
                type: 'info', // success / info / warning / error
              });
            }
          });
        } else {
          window.getApp.$emit('ALERT', {
            title: '안내', 
            message: '변경된 데이터가 없습니다.',
            type: 'info', // success / info / warning / error
          });
        }
      }
    },
    removeRowitem() {
      let selectData = this.$refs['itemTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.itemDeleteUrl);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // this.getProcessList();
              window.getApp.$emit('APP_REQUEST_SUCCESS');

              this.$_.forEach(selectData, item => {
                this.griditem.data = this.$_.reject(this.griditem.data, item);
              })
              this.$refs['itemTable'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
  }
};
</script>
